<script lang="ts" setup></script>

<template>
  <Html lang="fr">
    <Head>
      <Title>Mon espace client</Title>
      <Meta name="description" content="Espace client - Borne Recharge Service" />
    </Head>

    <Body>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </Body>
  </Html>
</template>
